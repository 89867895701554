import { useState, useEffect } from 'react'
import { firestore } from '@/lib/firebase'

const collectionRef = firestore.collection('surveys')

export const useSurvey = (params: { surveyId: App.Survey['id'] }) => {
  const [survey, setSurvey] = useState<App.Survey>()
  useEffect(() => {
    params.surveyId &&
      collectionRef
        .doc(params.surveyId)
        .get()
        .then(doc => setSurvey({ id: params.surveyId, ...doc.data() } as App.Survey))
  }, [])

  return survey
}
