import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { Layout, PageHeader, Form, Input, Button } from 'antd'
import { FormInstance } from 'antd/lib/form'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
}

type Props = {
  type: 'edit' | 'new'
  form: FormInstance
  onPressSubmit: (values: any) => void
  isUploadingSurveySchedule: boolean
}

const generateTitle = (type: Props['type']) => {
  return type === 'edit' ? 'アンケート編集' : '新規アンケート登録'
}
export default (props: Props) => {
  return (
    <Root title={generateTitle(props.type)}>
      <Layout>
        <StyledPageHeader title={generateTitle(props.type)} />
        <ContentBody>
          <Form {...layout} form={props.form} onFinish={props.onPressSubmit}>
            <Form.Item name="url" label="URL" rules={[{ required: true, message: 'URLは必須です' }]}>
              <Input placeholder="アンケートURL" />
            </Form.Item>
            <Form.Item
              name="trigger_day"
              label="配信日"
              rules={[{ required: true, message: '配信日は必須です' }]}>
              <Input type={'number'} min={0} addonAfter="日目に配信" />
            </Form.Item>
            <Form.Item
              name="trigger_hour"
              label="配信時間"
              rules={[{ required: true, message: '配信時間は必須です' }]}>
              <Input type={'number'} min={0} max={24} addonAfter="時に配信" />
            </Form.Item>
            <Form.Item
              name="relative_expire_hour"
              label="有効期限"
              rules={[{ required: true, message: '有効期限は必須です' }]}>
              <Input type={'number'} min={0} max={6} addonAfter="時間で有効期限切れ" />
            </Form.Item>
            <Form.Item label=" " colon={false}>
              <Button type="primary" htmlType="submit" loading={props.isUploadingSurveySchedule}>
                {props.type === 'edit' ? '変更を保存' : '登録'}
              </Button>
            </Form.Item>
          </Form>
        </ContentBody>
      </Layout>
    </Root>
  )
}

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
