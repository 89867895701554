import { Action } from '@/store/actions/action_creator'

export default (state: firebase.User | null = null, action: Action): firebase.User | null => {
  switch (action.type) {
    case 'AUTH#LOGIN':
      return action.payload.firebaseUser
    case 'AUTH#LOGOUT':
      return null
  }

  return state
}
