import Layout from './Layout'
import { useState, useEffect } from 'react'
import { firebase } from '@/lib/firebase'
import { SurveyRequest } from '@/lib/request'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { Form } from 'antd'
import { useSurvey } from '@/hooks/useSurvey'

export const useProps = (surveyId?: App.Survey['id']): FCProps<typeof Layout> => {
  const [isUploadingSurvey, setIsUploadingSurvey] = useState(false)
  const history = useHistory()
  const survey = useSurvey({ surveyId })
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      name: survey?.name,
    })
  }, [survey])

  return {
    type: survey?.id ? 'edit' : 'new',
    form,
    isUploadingSurvey,
    onPressSubmit: async (values: { name: string }) => {
      setIsUploadingSurvey(true)
      const token = await firebase.auth().currentUser?.getIdToken()
      if (survey?.id) {
        try {
          await SurveyRequest.put(token ?? '', { ...values, id: survey.id })
          message.success({
            content: '実験の編集が完了しました',
          })
          history.push('/surveys')
        } catch (e) {
          message.error({
            content: '実験の編集に失敗しました',
          })
        }
      } else {
        try {
          await SurveyRequest.post(token ?? '', values)
          message.success({
            content: '実験の追加に成功しました。',
          })
          history.push('/surveys')
        } catch (e) {
          message.error({
            content: '実験の追加に失敗しました',
          })
        }
      }
      setIsUploadingSurvey(false)
    },
  }
}
