import { useState, useEffect } from 'react'
import { firestore } from '@/lib/firebase'
import download from '@/lib/download'

const collectionRef = firestore.collection('appUsers')

export const useAppUsers = (params: { survey: App.Survey }) => {
  const [appUsers, setAppUsers] = useState<App.AppUser[]>([])

  useEffect(() => {
    let appUsersArray: App.AppUser[] = []
    return collectionRef.onSnapshot(async a => {
      a.docs.map(async b => {
        const survey = await collectionRef
          .doc(b.id)
          .collection('surveys')
          .doc(params.survey.id)
          .get()
        if (!survey.exists) return
        const data = b.data()
        const appUser = {
          ...data,
          id: b.id,
        } as App.AppUser
        appUsersArray = [...appUsersArray, appUser]
        setAppUsers(appUsersArray)
      })
    })
  }, [])

  const downloadAppUsers = async () => {
    if (appUsers.length === 0) return

    const title = ['user_id', 'user_name', 'email', 'os_id', 'device_model', 'fitbit_id', 'fitbit_token', 'refresh_token'].join(
      ',',
    )

    const contents = await Promise.all(appUsers
      .map(async (user: App.AppUser) => {
        const fitbit_user_id = user.fitbit_user_id ? user.fitbit_user_id : ''
        let accessToken = ''
        let refreshToken = ''

        if (fitbit_user_id) {
          const snapshot = await collectionRef.doc(user.id).collection('fitbitAuth').get()
          snapshot.forEach(doc => {
            const data = doc.data() as App.FitbitAuth
            if (data.fitbitUserId === fitbit_user_id) {
              accessToken = data.accessToken
              refreshToken = data.refreshToken
              return
            }
          })
        }

        return [
          user.id,
          '',
          '',
          '',
          '',
          fitbit_user_id,
          accessToken,
          refreshToken,
        ].join(',')
      }))
    download('user_db.csv', title + '\n' + contents.join('\n'))
  }

  return { appUsers, downloadAppUsers }
}
