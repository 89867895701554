import Layout from './Layout'
import { useSurvey } from '@/hooks/useSurvey'

type Params = {
  surveyId: App.Survey['id']
}

export const useProps = (params: Params): FCProps<typeof Layout> => {
  const survey = useSurvey({ surveyId: params.surveyId })

  return {
    survey,
  }
}
