import React from 'react'
import styled from 'styled-components'
import { Table, Button, Typography } from 'antd'
const { Title } = Typography

type Props = {
  survey: App.Survey
  appUsers: App.AppUser[]
  downloadAppUsers: () => void
}

export const Layout = (props: Props) => (
  <ContentBody>
    <StyledTitle level={3}>被験者一覧</StyledTitle>

    <StyledNewButton onClick={props.downloadAppUsers} type="primary">
      被験者情報をダウンロード
    </StyledNewButton>
    <Table
      dataSource={props.appUsers}
      columns={[
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          render: (id: App.AppUser['id']) => {
            return <div style={{ fontSize: 14 }}>{id}</div>
          },
        },
        {
          title: 'Fitbit ID',
          dataIndex: 'fitbit_user_id',
          key: 'fitbit_user_id',
          render: (fitbit_user_id: App.AppUser['fitbit_user_id']) => {
            return <div style={{ fontSize: 14 }}>{fitbit_user_id ?? '未連携'}</div>
          },
        },
      ]}
    />
  </ContentBody>
)

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`

const StyledNewButton = styled(Button)`
  margin: 12px 0;
`

const StyledTitle = styled(Title)`
  margin: 24px 0;
`
