import { useSurveySchedules } from '@/hooks/useSurveySchedules'
import { Layout } from './Layout'
import { useFullScreenLoading } from '@/context/FullScreenLoadingContext'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'

type Params = {
  survey: App.Survey
}

export const useProps = (params: Params): FCProps<typeof Layout> => {
  const survey = params.survey
  const { surveySchedules, deleteSurveySchedule, cloneSurveySchedule } = useSurveySchedules({
    survey: survey,
  })
  const { setVisible } = useFullScreenLoading()
  const history = useHistory()
  return {
    survey,
    surveySchedules,
    onPressDelete: async (id: App.SurveySchedule['id']) => {
      if (window.confirm('本当に削除しますか？')) {
        setVisible(true)
        try {
          await deleteSurveySchedule(id)
          message.success('削除に成功しました')
        } catch (e) {
          message.error('削除に失敗しました')
        } finally {
          setVisible(false)
        }
      }
    },
    onPressClone: async (surveySchedule: App.SurveySchedule) => {
      setVisible(true)
      try {
        await cloneSurveySchedule(surveySchedule)
        message.success('複製に成功しました')
      } catch (e) {
        message.error('複製に失敗しました')
      } finally {
        setVisible(false)
      }
    },
    onPressEdit: async id => {
      history.push(`${history.location.pathname}/${id}/edit`)
    },
  }
}
