const ActionCreator = {
  login: (payload: { firebaseUser: firebase.User; idToken: string }) => ({
    type: 'AUTH#LOGIN' as const,
    payload,
  }),
  logout: () => ({
    type: 'AUTH#LOGOUT' as const,
    payload: {},
  }),
}

export default ActionCreator

export type Action = ReturnType<typeof ActionCreator[keyof typeof ActionCreator]>
