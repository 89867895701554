import { useAppUsers } from '@/hooks/useAppUsers'
import { Layout } from './Layout'

type Params = {
  survey: App.Survey
}

export const useProps = (params: Params): FCProps<typeof Layout> => {
  const survey = params.survey
  const { appUsers, downloadAppUsers } = useAppUsers({ survey })

  return {
    survey,
    appUsers,
    downloadAppUsers,
  }
}
