import { getSurveys } from '@/lib/request'

const surveysActions = {
  REQUEST_SURVEYS: 'SURVEYS_REQUEST',
  RECEIVED_ERROR: 'SURVEYS_RECEIVED_ERROR',
  RECEIVED_SUCCESS: 'SURVEYS_RECEIVED_SUCCESS',
  REQUEST_RELOAD: 'SURVEYS_REQUEST_RELOAD',
}

const requestSurveys = () => ({
  type: surveysActions.REQUEST_SURVEYS,
})

const receivedError = (error: string) => ({
  type: surveysActions.RECEIVED_ERROR,
  payload: { error },
})

const receivedSuccess = (surveys: Survey[]) => ({
  type: surveysActions.RECEIVED_SUCCESS,
  payload: { surveys },
})

const requestReload = () => ({
  type: surveysActions.REQUEST_RELOAD,
})

const fetchSurveys = () => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken

    dispatch(requestSurveys())

    return getSurveys(idToken).then(
      (response: any) => dispatch(receivedSuccess(response)),
      (error: any) => dispatch(receivedError(error.message)),
    )
  }
}

export { surveysActions, requestReload, fetchSurveys }
