import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { AppUsersTable } from '@/components/tables/AppUsersTable'
import { Layout, PageHeader } from 'antd'

type Props = {
  survey?: App.Survey
}

export default (props: Props) => (
  <Root title={`実験：${props.survey?.name} の参加ユーザー`}>
    <Layout>
      <StyledPageHeader title={`実験詳細：${props.survey?.name}`} />
      {props.survey ? <AppUsersTable survey={props.survey} /> : undefined}
    </Layout>
  </Root>
)

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`
