import { surveyActions } from '@/store/actions/survey'

const initialState: SurveyItem = {
  needLoading: true,
  isLoading: false,
  message: '',
}

const survey = (state = initialState, action: any): SurveyItem => {
  switch (action.type) {
    case surveyActions.REQUEST_SURVEY:
      return { ...state, needLoading: false, isLoading: true }
    case surveyActions.RECEIVED_ERROR:
      return { ...state, needLoading: false, isLoading: false, message: action.payload.error }
    case surveyActions.RECEIVED_SUCCESS:
      return { ...state, needLoading: false, isLoading: false, survey: action.payload.survey }
    case surveyActions.REQUEST_RELOAD:
      return { ...state, needLoading: true }
    default:
      return state
  }
}

export { survey }
