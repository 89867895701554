import Layout from './Layout'
import { useState, useEffect } from 'react'
import { firebase } from '@/lib/firebase'
import { SurveyScheduleRequest } from '@/lib/request'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { Form } from 'antd'
import { useSurveyScheudle } from '@/hooks/useSurveySchedule'
import { getRelativeExpireHour, getAbsoluteExpireDay, getAbsoluteExpireHour } from '@/lib/surveyScheduleUtils'

export const useProps = (
  surveyId?: App.Survey['id'],
  surveyScheduleId?: App.SurveySchedule['id'],
): FCProps<typeof Layout> => {
  const [isUploadingSurveySchedule, setIsUploadingSurveySchedule] = useState(false)
  const { survey, surveySchedule } = useSurveyScheudle({
    surveyId: surveyId,
    surveyScheduleId: surveyScheduleId,
  })
  const [form] = Form.useForm()
  const history = useHistory()

  useEffect(() => {
    form.setFieldsValue({
      url: surveySchedule?.url,
      trigger_day: surveySchedule?.trigger_day,
      trigger_hour: surveySchedule?.trigger_hour,
      relative_expire_hour: surveySchedule ? getRelativeExpireHour(surveySchedule) : undefined,
    })
  }, [survey, surveySchedule])

  return {
    type: surveySchedule?.id ? 'edit' : 'new',
    form,
    isUploadingSurveySchedule,
    onPressSubmit: async (values: {
      url: App.SurveySchedule['url']
      trigger_day: App.SurveySchedule['trigger_day']
      trigger_hour: App.SurveySchedule['trigger_hour']
      relative_expire_hour: number
    }) => {
      setIsUploadingSurveySchedule(true)
      const token = await firebase.auth().currentUser?.getIdToken()
      const payload = {
        url: values.url,
        trigger_day: values.trigger_day,
        trigger_hour: values.trigger_hour,
        expire_day: getAbsoluteExpireDay(
          values.trigger_day,
          values.trigger_hour,
          values.relative_expire_hour,
        ),
        expire_hour: getAbsoluteExpireHour(values.trigger_hour, values.relative_expire_hour),
      }
      if (surveySchedule?.id) {
        try {
          await SurveyScheduleRequest.put(token ?? '', survey?.id, { ...payload, id: surveySchedule?.id })
          message.success({
            content: '配信スケジュールの編集が完了しました',
          })
          history.push(`/surveys/${survey?.id}/schedules`)
        } catch (e) {
          message.error({
            content: '配信スケジュールの編集に失敗しました',
          })
        }
      } else {
        try {
          await SurveyScheduleRequest.post(token ?? '', survey?.id, payload)
          message.success({
            content: '配信スケジュールの追加に成功しました。',
          })
          history.push(`/surveys/${survey?.id}/schedules`)
        } catch (e) {
          message.error({
            content: '配信スケジュールの追加に失敗しました',
          })
        }
      }
      setIsUploadingSurveySchedule(false)
    },
  }
}
