import { appUsersActions } from '@/store/actions/appusers'

const initialState: SurveyAppUsers = {
  needLoading: true,
  isLoading: false,
  message: '',
}

const appUsers = (state = initialState, action: any): SurveyAppUsers => {
  switch (action.type) {
    case appUsersActions.REQUEST_APPUSERS:
      return { ...state, needLoading: false, isLoading: true }
    case appUsersActions.RECEIVED_ERROR:
      return { ...state, needLoading: false, isLoading: false, message: action.payload.error }
    case appUsersActions.RECEIVED_SUCCESS:
      return { ...state, needLoading: false, isLoading: false, appUsers: action.payload.appUsers }
    case appUsersActions.REQUEST_RELOAD:
      return { ...state, needLoading: true }
    default:
      return state
  }
}

export { appUsers }
