import { useSurveys } from '@/hooks/useSurveys'
import { Layout } from './Layout'
import { useFullScreenLoading } from '@/context/FullScreenLoadingContext'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'

export const useProps = (): FCProps<typeof Layout> => {
  const { surveys, deleteSurvey, cloneSurvey } = useSurveys()
  const { setVisible } = useFullScreenLoading()
  const history = useHistory()
  return {
    surveys,
    onPressDelete: async (id: App.Survey['id']) => {
      if (window.confirm('本当に削除しますか？')) {
        setVisible(true)
        try {
          await deleteSurvey(id)
          message.success('削除に成功しました')
        } catch (e) {
          message.error('削除に失敗しました')
        } finally {
          setVisible(false)
        }
      }
    },
    onPressClone: async survey => {
      setVisible(true)
      try {
        await cloneSurvey(survey)
        message.success('複製に成功しました')
      } catch (e) {
        message.error('複製に失敗しました')
      } finally {
        setVisible(false)
      }
    },
    onPressSueveySchedules: async id => {
      history.push(`${history.location.pathname}/${id}/schedules`)
    },
    onPressAppUsers: async id => {
      history.push(`${history.location.pathname}/${id}/appusers`)
    },
    onPressEdit: async id => {
      history.push(`${history.location.pathname}/${id}/edit`)
    },
  }
}
