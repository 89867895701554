import { postSurvey, putSurvey, deleteSurvey, cloneSurvey } from '@/lib/request'
import { requestReload } from './surveys'

const surveyFormActions = {
  CHANGE_NAME: 'SURVEY_CHANGE_NAME',
  CHANGE_SURVEY: 'SURVEY_CHANGE_ALL',
  RESET_SURVEY: 'RESET_SURVEY',
  REQUEST_POST_SURVEY: 'SURVEY_REQUEST_POST',
  REQUEST_PUT_SURVEY: 'SURVEY_REQUEST_PUT',
  REQUEST_DELETE_SURVEY: 'SURVEY_REQUEST_DELETE',
  REQUEST_CLONE_SURVEY: 'SURVEY_REQUEST_CLONE',
  RECEIVED_ERROR: 'SURVEY_RECEIVED_ERROR',
  RECEIVED_SUCCESS: 'SURVEY_RECEIVED_SUCCESS',
}

const changeNameValue = (value: string) => ({
  type: surveyFormActions.CHANGE_NAME,
  payload: { value },
})

const changeSurvey = (value: Survey) => ({
  type: surveyFormActions.CHANGE_SURVEY,
  payload: { value },
})

const resetSurvey = () => ({
  type: surveyFormActions.RESET_SURVEY,
})

const requestPostSurvey = () => ({
  type: surveyFormActions.REQUEST_POST_SURVEY,
})

const requestPutSurvey = () => ({
  type: surveyFormActions.REQUEST_PUT_SURVEY,
})

const requestDeleteSurvey = () => ({
  type: surveyFormActions.REQUEST_DELETE_SURVEY,
})

const requestCloneSurvey = () => ({
  type: surveyFormActions.REQUEST_CLONE_SURVEY,
})

const receivedError = (error: string) => ({
  type: surveyFormActions.RECEIVED_ERROR,
  payload: { error },
})

const receivedSuccess = (message: string) => ({
  type: surveyFormActions.RECEIVED_SUCCESS,
  payload: { message },
})

const submitPostSurvey = () => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken
    const survey = getState().surveyForm.survey

    dispatch(requestPostSurvey())

    return postSurvey(idToken, survey).then(
      (response: any) => {
        dispatch(receivedSuccess('Survey Successfully added'))
        dispatch(requestReload())
      },
      (error: any) => dispatch(receivedError(error.message)),
    )
  }
}

const submitUpdateSurvey = () => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken
    const survey = getState().surveyForm.survey

    dispatch(requestPutSurvey())

    return putSurvey(idToken, survey.id, survey).then(
      (response: any) => {
        dispatch(receivedSuccess('Survey successfully updated'))
        dispatch(requestReload())
      },
      (error: any) => dispatch(receivedError(error.message)),
    )
  }
}

const submitDeleteSurvey = () => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken
    const survey = getState().surveyForm.survey

    dispatch(requestDeleteSurvey())

    return deleteSurvey(idToken, survey.id).then(
      (response: any) => {
        dispatch(receivedSuccess('Survey successfully deleted'))
        dispatch(requestReload())
      },
      (error: any) => dispatch(receivedError(error.message)),
    )
  }
}

const submitCloneSurvey = () => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken
    const survey = getState().surveyForm.survey

    dispatch(requestCloneSurvey())

    return cloneSurvey(idToken, survey.id, survey).then(
      (response: any) => {
        dispatch(receivedSuccess('Survey Successfully added'))
        dispatch(requestReload())
      },
      (error: any) => dispatch(receivedError(error.message)),
    )
  }
}

export {
  surveyFormActions,
  submitPostSurvey,
  submitUpdateSurvey,
  submitDeleteSurvey,
  submitCloneSurvey,
  changeNameValue,
  changeSurvey,
  resetSurvey,
}
