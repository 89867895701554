import { combineReducers } from 'redux'
import idToken from './idToken'
import loginUser from './loginUser'
import { surveys } from './surveys'
import { survey } from './survey'
import { surveyForm } from './surveyForm'
import { scheduleForm } from './scheduleForm'
import { appUsers } from './appusers'

export default combineReducers<RootReducer>({
  idToken,
  loginUser,
  surveys,
  survey,
  surveyForm,
  scheduleForm,
  appUsers,
})
