const baseUrl = `https://asia-northeast1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`

const makeUrl = (endpoint: string) => {
  return `${baseUrl}/${endpoint}`
}

const makeHeaders = (idToken: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${idToken}`,
})

const postRequest = async <T extends {}>(endPoint: string, idToken: string, payload: T) => {
  const response = await fetch(makeUrl(endPoint), {
    method: 'POST',
    headers: makeHeaders(idToken),
    body: JSON.stringify(payload),
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return {}
}

const getRequest = async <T extends {}>(endPoint: string, idToken: string): Promise<T> => {
  const response = await fetch(makeUrl(endPoint), {
    method: 'GET',
    headers: makeHeaders(idToken),
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json() as Promise<T>
}

const putRequest = async <T extends {}>(endPoint: string, idToken: string, payload: T) => {
  const response = await fetch(makeUrl(endPoint), {
    method: 'PUT',
    headers: makeHeaders(idToken),
    body: JSON.stringify(payload),
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return {}
}

const deleteRequest = async <T extends {}>(endPoint: string, idToken: string) => {
  const response = await fetch(makeUrl(endPoint), {
    method: 'DELETE',
    headers: makeHeaders(idToken),
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return {}
}

export const SurveyRequest = {
  post: async (
    idToken: string,
    payload: {
      name: App.Survey['name']
    },
  ) => {
    return postRequest('api/surveys', idToken, payload)
  },
  put: async (
    idToken: string,
    payload: {
      id?: App.Survey['id']
      name: App.Survey['name']
    },
  ) => {
    return putRequest(`api/surveys/${payload.id}`, idToken, payload)
  },
  delete: async (idToken: string, payload: { id: App.Survey['id'] }) => {
    return deleteRequest(`api/surveys/${payload.id}`, idToken)
  },
  clone: async (idToken: string, payload: { id: App.Survey['id']; name: App.Survey['name'] }) => {
    return postRequest(`api/surveys/${payload.id}/clone`, idToken, { name: payload.name })
  },
}

export const SurveyScheduleRequest = {
  post: async (
    idToken: string,
    surveyId: App.Survey['id'],
    payload: {
      url: App.SurveySchedule['url']
      trigger_day: App.SurveySchedule['trigger_day']
      trigger_hour: App.SurveySchedule['trigger_hour']
      expire_day: App.SurveySchedule['expire_day']
      expire_hour: App.SurveySchedule['expire_hour']
    },
  ) => {
    return postRequest(`api/surveys/${surveyId}/schedules`, idToken, payload)
  },
  put: async (
    idToken: string,
    surveyId: App.Survey['id'],
    payload: {
      id?: App.SurveySchedule['id']
      url: App.SurveySchedule['url']
      trigger_day: App.SurveySchedule['trigger_day']
      trigger_hour: App.SurveySchedule['trigger_hour']
      expire_day: App.SurveySchedule['expire_day']
      expire_hour: App.SurveySchedule['expire_hour']
    },
  ) => {
    return putRequest(`api/surveys/${surveyId}/schedules/${payload.id}`, idToken, payload)
  },
  delete: async (idToken: string, payload: { surveyId: App.Survey['id']; id: App.SurveySchedule['id'] }) => {
    return deleteRequest(`api/surveys/${payload.surveyId}/schedules/${payload.id}`, idToken)
  },
  clone: async (
    idToken: string,
    payload: { surveyId: App.Survey['id']; surveySchedule: App.SurveySchedule },
  ) => {
    return postRequest<App.SurveySchedule>(`api/surveys/${payload.surveyId}/schedules`, idToken, {
      url: payload.surveySchedule.url,
      trigger_day: Number(payload.surveySchedule.trigger_day) + 1,
      trigger_hour: Number(payload.surveySchedule.trigger_hour),
      expire_day: Number(payload.surveySchedule.expire_day) + 1,
      expire_hour: Number(payload.surveySchedule.expire_hour),
    })
  },
}

// Surveys
const getSurveys = (idToken: string) => getRequest<Surveys[]>('api/surveys', idToken)

const getSurvey = (idToken: string, surveyId: string) =>
  getRequest<Survey>(`api/surveys/${surveyId}`, idToken)

const postSurvey = (idToken: string, payload: { name: App.Survey['name'] }) =>
  postRequest<Survey>('api/surveys', idToken, payload)

const putSurvey = (idToken: string, surveyId: App.Survey['id'], payload: { name: App.Survey['name'] }) =>
  putRequest<Survey>(`api/surveys/${surveyId}`, idToken, payload)

const deleteSurvey = (idToken: string, surveyId: string) =>
  deleteRequest<{}>(`api/surveys/${surveyId}`, idToken)

const cloneSurvey = (idToken: string, surveyId: string, payload: { name: string }) =>
  postRequest<Survey>(`api/surveys/${surveyId}/clone`, idToken, payload)

const getSurveyAppUsers = (idToken: string, surveyId: string) =>
  getRequest<Survey>(`api/surveys/${surveyId}/appusers`, idToken)

// Schedule
const postSchedule = (idToken: string, surveyId: string, payload: Schedule) =>
  postRequest<Schedule>(`api/surveys/${surveyId}/schedules`, idToken, payload)

const putSchedule = (idToken: string, surveyId: string, scheduleId: string, payload: Schedule) =>
  putRequest<Schedule>(`api/surveys/${surveyId}/schedules/${scheduleId}`, idToken, payload)

const deleteSchedule = (idToken: string, surveyId: string, scheduleId: string) =>
  deleteRequest<{}>(`api/surveys/${surveyId}/schedules/${scheduleId}`, idToken)

export {
  getSurveys,
  getSurvey,
  postSurvey,
  putSurvey,
  deleteSurvey,
  cloneSurvey,
  getSurveyAppUsers,
  postSchedule,
  putSchedule,
  deleteSchedule,
}
