import { getSurveyAppUsers } from '@/lib/request'

const appUsersActions = {
  REQUEST_APPUSERS: 'APPUSERS_REQUEST',
  RECEIVED_ERROR: 'APPUSERS_RECEIVED_ERROR',
  RECEIVED_SUCCESS: 'APPUSERS_RECEIVED_SUCCESS',
  REQUEST_RELOAD: 'APPUSERS_REQUEST_RELOAD',
}

const requestAppUsers = () => ({
  type: appUsersActions.REQUEST_APPUSERS,
})

const receivedError = (error: string) => ({
  type: appUsersActions.RECEIVED_ERROR,
  payload: { error },
})

const receivedSuccess = (appUsers: AppUser[]) => ({
  type: appUsersActions.RECEIVED_SUCCESS,
  payload: { appUsers },
})

const requestReload = () => ({
  type: appUsersActions.REQUEST_RELOAD,
})

const fetchAppUsers = (surveyId: string) => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken

    dispatch(requestAppUsers())

    return getSurveyAppUsers(idToken, surveyId).then(
      (response: any) => dispatch(receivedSuccess(response.users)),
      (error: any) => dispatch(receivedError(error.message)),
    )
  }
}

export { appUsersActions, requestReload, fetchAppUsers }
