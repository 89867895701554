import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Table, Button } from 'antd'
import moment from 'moment'

type Props = {
  surveys: App.Survey[]
  onPressSueveySchedules: (id: App.Survey['id']) => void
  onPressAppUsers: (id: App.Survey['id']) => void
  onPressEdit: (id: App.Survey['id']) => void
  onPressClone: (survey: App.Survey) => void
  onPressDelete: (id: App.Survey['id']) => void
}

export const Layout = (props: Props) => (
  <ContentBody>
    <Link to="/surveys/new">
      <StyledNewButton type="primary">新規登録</StyledNewButton>
    </Link>
    <Table
      dataSource={props.surveys}
      columns={[
        {
          title: '実験名',
          dataIndex: 'name',
          key: 'name',
          render: (name: App.Survey['name']) => {
            return <div style={{ fontSize: 14 }}>{name}</div>
          },
        },
        {
          title: '実験コード',
          dataIndex: 'code',
          key: 'code',
          render: (code: App.Survey['code']) => {
            return <div style={{ fontSize: 14 }}>{code}</div>
          },
        },
        {
          title: '作成日時',
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: (a, b) => {
            const d1 = a.createdAt ? moment(a.createdAt.toDate()).unix() : 0
            const d2 = b.createdAt ? moment(b.createdAt.toDate()).unix() : 0
            return d1 - d2
          },
          defaultSortOrder: 'descend',
          render: (createdAt?: App.Survey['createdAt']) => (
            <div style={{ fontSize: 13, width: 130 }}>{createdAt?.toDate().toLocaleString('ja-JP')}</div>
          ),
        },
        {
          title: '',
          key: 'action',
          render: (survey: App.Survey) => {
            return (
              <div style={{ display: 'flex' }}>
                <StyledButton onClick={() => props.onPressSueveySchedules(survey.id)}>詳細</StyledButton>
                <StyledButton onClick={() => props.onPressAppUsers(survey.id)}>被験者一覧</StyledButton>
                <StyledButton onClick={() => props.onPressEdit(survey.id)}>編集</StyledButton>
                <StyledButton onClick={() => props.onPressClone(survey)}>複製</StyledButton>
                <StyledButton danger onClick={() => props.onPressDelete(survey.id)}>
                  削除
                </StyledButton>
              </div>
            )
          },
        },
      ]}
    />
  </ContentBody>
)

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`

const StyledButton = styled(Button)`
  margin: 0 12px;
`

const StyledNewButton = styled(Button)`
  margin: 12px 0;
`
