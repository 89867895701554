import { surveyFormActions } from '@/store/actions/surveyForm'

const initialState: SurveyForm = {
  message: '',
  isLoading: false,
  survey: {
    name: '',
  },
}

const surveyForm = (state = initialState, action: any): SurveyForm => {
  switch (action.type) {
    case surveyFormActions.CHANGE_NAME:
      return {
        ...state,
        survey: {
          ...state.survey,
          name: action.payload.value,
        },
      }
    case surveyFormActions.CHANGE_SURVEY:
      return { ...state, survey: action.payload.value }
    case surveyFormActions.RESET_SURVEY:
      return initialState
    case surveyFormActions.REQUEST_POST_SURVEY:
      return { ...state, isLoading: true }
    case surveyFormActions.REQUEST_PUT_SURVEY:
      return { ...state, isLoading: true }
    case surveyFormActions.REQUEST_DELETE_SURVEY:
      return { ...state, isLoading: true }
    case surveyFormActions.REQUEST_CLONE_SURVEY:
      return { ...state, isLoading: true }
    case surveyFormActions.RECEIVED_ERROR:
      return {
        ...state,
        isLoading: false,
        message: action.payload.error,
      }
    case surveyFormActions.RECEIVED_SUCCESS:
      return {
        ...initialState,
        message: action.payload.message,
      }
    default:
      return state
  }
}

export { surveyForm }
