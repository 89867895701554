import React from 'react'
import { createRouter } from './createRouter'
import { Top } from '@/pages/top'
import { Login } from '@/pages/login'
import { Surveys } from '@/pages/surveys/list'
import { EditSurvey } from '@/pages/surveys/edit'
import { SurveySchedules } from '@/pages/surveySchedules/list'
import { EditSurveySchedule } from '@/pages/surveySchedules/edit'
import { AppUsers } from '@/pages/appUsers/list'

export const Routes: CustomRoute[] = [
  {
    needAuth: false,
    path: '/login',
    component: Login,
  },
  {
    needAuth: true,
    path: '/surveys',
    component: Surveys,
  },
  {
    needAuth: true,
    path: '/surveys/new',
    component: () => <EditSurvey />,
  },
  {
    needAuth: true,
    path: '/surveys/:surveyId/edit',
    component: ({ params }) => {
      return <EditSurvey surveyId={params.surveyId} />
    },
  },
  {
    needAuth: true,
    path: '/surveys/:surveyId/schedules',
    component: ({ params }) => {
      return <SurveySchedules surveyId={params.surveyId} />
    },
  },
  {
    needAuth: true,
    path: '/surveys/:surveyId/schedules/new',
    component: ({ params }) => {
      return <EditSurveySchedule surveyId={params.surveyId} />
    },
  },
  {
    needAuth: true,
    path: '/surveys/:surveyId/schedules/:surveyScheduleId/edit',
    component: ({ params }) => {
      return <EditSurveySchedule surveyId={params.surveyId} surveyScheduleId={params.surveyScheduleId} />
    },
  },
  {
    needAuth: true,
    path: '/surveys/:surveyId/appusers',
    component: ({ params }) => {
      return <AppUsers surveyId={params.surveyId} />
    },
  },
  {
    needAuth: true,
    path: '/',
    component: Top,
  },
]

export const Router = createRouter(Routes)
