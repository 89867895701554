import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { SurveySchedulesTable } from '@/components/tables/SurveySchedulesTable'
import { Layout, PageHeader } from 'antd'

type Props = {
  survey?: App.Survey
}

export default (props: Props) => (
  <Root title={`実験詳細：${props.survey?.name}`}>
    <Layout>
      <StyledPageHeader title={`実験詳細：${props.survey?.name}`} />
      {props.survey ? <SurveySchedulesTable survey={props.survey} /> : undefined}
    </Layout>
  </Root>
)

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`
