import React from 'react'
import styled from 'styled-components'
import { WithoutAuth as Root } from '@/components/Root'
import Logo from '@/components/Logo'
import { LayoutProps } from './props'
import { Layout, Form, Input, Button, Alert } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'

const validateMessages = {
  types: {
    email: 'メールアドレスの形式が間違っています',
  },
}

export default ({ errorMessage, loading, onFinish }: LayoutProps) => (
  <StyledRoot title="ログイン">
    <StyledLayout>
      {errorMessage && <StlyedAlert message={errorMessage} type="error" showIcon />}
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <StyledForm
        name="basic"
        initialValues={{ remember: true }}
        validateMessages={validateMessages}
        onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'メールアドレスを入力してください',
            },
            { type: 'email' },
          ]}>
          <Input prefix={<MailOutlined />} placeholder="メールアドレス" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'パスワードを入力してください',
            },
          ]}>
          <Input.Password prefix={<LockOutlined />} type="password" placeholder="パスワード" />
        </Form.Item>
        <Form.Item>
          <StyledButton type="primary" htmlType="submit" disabled={loading}>
            {loading ? 'ログインしています...' : 'ログイン'}
          </StyledButton>
        </Form.Item>
      </StyledForm>
    </StyledLayout>
  </StyledRoot>
)

const LogoWrapper = styled.div`
  padding: 0 4px;
  margin-bottom: 2rem;
`

const StyledLayout = styled(Layout)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const StyledRoot = styled(Root)`
  height: 100%;
`
const StyledButton = styled(Button)`
  width: 100%;
`

const StyledForm = styled(Form)`
  box-sizing: border-box;
  width: 95%;
  max-width: 330px;
  margin-top: -30px;
`

const StlyedAlert = styled(Alert)`
  margin-bottom: 30px;
`
