import { useState, useEffect } from 'react'
import { firestore } from '@/lib/firebase'
import { useSurvey } from './useSurvey'

export const useSurveyScheudle = (params: {
  surveyId: App.Survey['id']
  surveyScheduleId: App.SurveySchedule['id']
}) => {
  const survey = useSurvey({ surveyId: params.surveyId })
  const [surveySchedule, setSurveySchedule] = useState<App.SurveySchedule>()
  useEffect(() => {
    params.surveyId &&
      params.surveyScheduleId &&
      firestore
        .collection(`surveys/${params.surveyId}/schedules`)
        .doc(params.surveyScheduleId)
        .get()
        .then(doc => setSurveySchedule({ id: params.surveyScheduleId, ...doc.data() } as App.SurveySchedule))
  }, [])

  return { survey, surveySchedule }
}
