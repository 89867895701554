import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { SurveysTable } from '@/components/tables/SurveysTable'
import { Layout, PageHeader } from 'antd'

const pageTitle = '実験一覧'

export default () => (
  <Root title={pageTitle}>
    <Layout>
      <StyledPageHeader title={pageTitle} />
      <SurveysTable />
    </Layout>
  </Root>
)

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`
