import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Table, Button, Descriptions, Typography } from 'antd'
import { getRelativeExpireHour } from '@/lib/surveyScheduleUtils'
const { Title } = Typography

type Props = {
  survey: App.Survey
  surveySchedules: App.SurveySchedule[]
  onPressEdit: (id: App.SurveySchedule['id']) => void
  onPressClone: (surveySchedule: App.SurveySchedule) => void
  onPressDelete: (id: App.SurveySchedule['id']) => void
}

export const Layout = (props: Props) => (
  <ContentBody>
    <Descriptions bordered>
      <Descriptions.Item label="ID" span={2}>
        {props.survey.id}
      </Descriptions.Item>
      <Descriptions.Item label="実験名" span={2}>
        {props.survey.name}
      </Descriptions.Item>
      <Descriptions.Item label="実験コード" span={2}>
        {props.survey.code}
      </Descriptions.Item>
      <Descriptions.Item label="作成日時" span={2}>
        {props.survey.createdAt?.toDate().toLocaleString('ja-JP')}
      </Descriptions.Item>
    </Descriptions>

    <StyledTitle level={3}>配信スケジュール</StyledTitle>

    <Link to={`/surveys/${props.survey.id}/schedules/new`}>
      <StyledNewButton type="primary">新規登録</StyledNewButton>
    </Link>
    <Table
      dataSource={props.surveySchedules}
      columns={[
        {
          title: '配信日',
          dataIndex: 'trigger_day',
          key: 'trigger_day',
          sorter: (a, b) => a.trigger_day - b.trigger_day,
          defaultSortOrder: 'ascend',
          render: (trigger_day: App.SurveySchedule['trigger_day']) => {
            return <div style={{ fontSize: 14 }}>{trigger_day}日目</div>
          },
        },
        {
          title: '配信開始時間',
          dataIndex: 'trigger_hour',
          key: 'trigger_hour',
          sorter: (a, b) => a.trigger_hour - b.trigger_hour,
          defaultSortOrder: 'ascend',
          render: (trigger_hour: App.SurveySchedule['trigger_hour']) => {
            return <div style={{ fontSize: 14 }}>{trigger_hour}時</div>
          },
        },
        {
          title: '有効期限',
          key: 'relative_expire_hour',
          render: (surveySchedule: App.SurveySchedule) => {
            return <div style={{ fontSize: 14 }}>{getRelativeExpireHour(surveySchedule)}時間</div>
          },
        },
        {
          title: 'URL',
          dataIndex: 'url',
          key: 'url',
          render: (url: App.SurveySchedule['url']) => {
            return (
              <a href={url} target="_blank" rel="noopener noreferrer">
                <div style={{ fontSize: 14 }}>{url}</div>
              </a>
            )
          },
        },
        {
          title: '',
          key: 'action',
          render: (surveySchedule: App.SurveySchedule) => {
            return (
              <div style={{ display: 'flex' }}>
                <StyledButton onClick={() => props.onPressEdit(surveySchedule.id)}>編集</StyledButton>
                <StyledButton onClick={() => props.onPressClone(surveySchedule)}>複製</StyledButton>
                <StyledButton danger onClick={() => props.onPressDelete(surveySchedule.id)}>
                  削除
                </StyledButton>
              </div>
            )
          },
        },
      ]}
    />
  </ContentBody>
)

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`

const StyledButton = styled(Button)`
  margin: 0 12px;
`

const StyledNewButton = styled(Button)`
  margin: 12px 0;
`

const StyledTitle = styled(Title)`
  margin: 24px 0;
`
