import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect, useParams } from 'react-router-dom'
import NotFound from '@/pages/not_found'
import { useAuth } from '@/lib/firebase'

export const createRouter = (routes: CustomRoute[]) => () => {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map(({ needAuth, path, component }) => (
          <Route exact key={path} path={path}>
            <AuthComponent Comp={component} needAuth={needAuth} />
          </Route>
        ))}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

const AuthComponent = ({ Comp, needAuth }: { Comp: React.FC<{ params: any }>; needAuth: boolean }) => {
  useAuth()
  const idToken = useSelector<RootReducer, string | null>(({ idToken }) => idToken)
  const p = useParams() ?? {}

  if (!needAuth) return <Comp params={p} />
  return idToken ? <Comp params={p} /> : <Redirect to="/login" />
}
