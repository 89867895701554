export const getRelativeExpireHour = (surveySchedule: App.SurveySchedule): number => {
  return Number(surveySchedule.trigger_day) === Number(surveySchedule.expire_day)
    ? Number(surveySchedule.expire_hour) - Number(surveySchedule.trigger_hour)
    : Number(surveySchedule.expire_hour) + 24 - Number(surveySchedule.trigger_hour)
}

export const getAbsoluteExpireDay = (
  trigger_day: App.SurveySchedule['trigger_day'],
  trigger_hour: App.SurveySchedule['trigger_hour'],
  relative_expire_hour: number,
): App.SurveySchedule['expire_day'] => {
  return Number(trigger_hour) + Number(relative_expire_hour) < 24 ? trigger_day : Number(trigger_day) + 1
}

export const getAbsoluteExpireHour = (
  trigger_hour: App.SurveySchedule['trigger_hour'],
  relative_expire_hour: number,
): App.SurveySchedule['expire_hour'] => {
  return Number(trigger_hour) + Number(relative_expire_hour) < 24
    ? Number(trigger_hour) + Number(relative_expire_hour)
    : Number(trigger_hour) + Number(relative_expire_hour) - 24
}
