import { surveysActions } from '@/store/actions/surveys'

const initialState: Surveys = {
  needLoading: true,
  isLoading: false,
  message: '',
  surveys: [],
}

const surveys = (state = initialState, action: any): Surveys => {
  switch (action.type) {
    case surveysActions.REQUEST_SURVEYS:
      return { ...state, needLoading: false, isLoading: true }
    case surveysActions.RECEIVED_ERROR:
      return { ...state, needLoading: false, isLoading: false, message: action.payload.error }
    case surveysActions.RECEIVED_SUCCESS:
      return { ...state, needLoading: false, isLoading: false, surveys: action.payload.surveys }
    case surveysActions.REQUEST_RELOAD:
      return { ...state, needLoading: true }
    default:
      return state
  }
}

export { surveys }
