import React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { configureStore } from '@/store'
import { Router } from '@/routes'
import { FullScreenLoading } from './context/FullScreenLoadingContext'
import '@/lib/firebase'

const { store, persistor } = configureStore()

export default () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <FullScreenLoading>
        <Router />
      </FullScreenLoading>
    </PersistGate>
  </Provider>
)
