import React from 'react'
import Layout from './Layout'
import { useProps } from './useProps'

export const EditSurveySchedule = (params: {
  surveyId: App.Survey['id']
  surveyScheduleId?: App.SurveySchedule['id']
}) => {
  return <Layout {...useProps(params.surveyId, params.surveyScheduleId)} />
}
