import { scheduleFormActions } from '@/store/actions/scheduleForm'

const initialState: ScheduleForm = {
  message: '',
  isLoading: false,
  schedule: {
    id: '',
    url: '',
    trigger_day: 0,
    trigger_hour: 0,
    expire_day: 0,
    expire_hour: 0,
  },
}

const scheduleForm = (state = initialState, action: any): ScheduleForm => {
  switch (action.type) {
    case scheduleFormActions.CHANGE_SCHEDULE:
      return { ...state, schedule: action.payload.value }
    case scheduleFormActions.RESET_SCHEDULE:
      return initialState
    case scheduleFormActions.REQUEST_POST_SCHEDULE:
      return { ...state, isLoading: true }
    case scheduleFormActions.REQUEST_PUT_SCHEDULE:
      return { ...state, isLoading: true }
    case scheduleFormActions.REQUEST_DELETE_SCHEDULE:
      return { ...state, isLoading: true }
    case scheduleFormActions.RECEIVED_ERROR:
      return {
        ...state,
        isLoading: false,
        message: action.payload.error,
      }
    case scheduleFormActions.RECEIVED_SUCCESS:
      return {
        ...initialState,
        message: action.payload.message,
      }
    default:
      return { ...state, schedule: schedule(state.schedule, action) }
  }
}

type Action = {
  type: string
  payload: {
    value: any
  }
}

const schedule = (state: Schedule, action: Action): Schedule => {
  switch (action.type) {
    case scheduleFormActions.CHANGE_ID:
      return { ...state, id: action.payload.value }
    case scheduleFormActions.CHANGE_URL:
      return { ...state, url: action.payload.value }
    case scheduleFormActions.CHANGE_TRIGGER_DAY:
      return { ...state, trigger_day: action.payload.value }
    case scheduleFormActions.CHANGE_TRIGGER_HOUR:
      return { ...state, trigger_hour: action.payload.value }
    case scheduleFormActions.CHANGE_EXPIRE_DAY:
      return { ...state, expire_day: action.payload.value }
    case scheduleFormActions.CHANGE_EXPIRE_HOUR:
      return { ...state, expire_hour: action.payload.value }
    default:
      return state
  }
}

export { scheduleForm }
