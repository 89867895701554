import { useState, useEffect, useCallback } from 'react'
import { firestore, auth } from '@/lib/firebase'
import { SurveyRequest } from '@/lib/request'

const collectionRef = firestore.collection('surveys')

export const useSurveys = () => {
  const [surveys, setSurveys] = useState<App.Survey[]>([])
  useEffect(() => {
    return collectionRef.orderBy('createdAt', 'desc').onSnapshot(async a => {
      const p = await Promise.all(
        a.docs.map<Promise<App.Survey>>(async b => {
          const data = b.data()
          return {
            ...data,
            id: b.id,
          } as App.Survey
        }),
      )
      setSurveys(p)
    })
  }, [])

  const deleteSurvey = useCallback(async (id: App.Survey['id']) => {
    return new Promise(r => {
      auth.onAuthStateChanged(async a => {
        const token = await a?.getIdToken()
        await SurveyRequest.delete(token ?? '', { id })
        r()
      })
    })
  }, [])

  const cloneSurvey = useCallback(async (survey: App.Survey) => {
    return new Promise(r => {
      auth.onAuthStateChanged(async a => {
        const token = await a?.getIdToken()
        const name = `${survey.name} - COPY`
        await SurveyRequest.clone(token ?? '', { id: survey.id, name })
        r()
      })
    })
  }, [])

  return { surveys, deleteSurvey, cloneSurvey }
}
