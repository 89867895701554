import { getSurvey } from '@/lib/request'

const surveyActions = {
  REQUEST_SURVEY: 'SURVEY_REQUEST',
  RECEIVED_ERROR: 'SURVEY_RECEIVED_ERROR',
  RECEIVED_SUCCESS: 'SURVEY_RECEIVED_SUCCESS',
  REQUEST_RELOAD: 'SURVEY_REQUEST_RELOAD',
}

const requestSurvey = () => ({
  type: surveyActions.REQUEST_SURVEY,
})

const receivedError = (error: string) => ({
  type: surveyActions.RECEIVED_ERROR,
  payload: { error },
})

const receivedSuccess = (survey: Survey) => ({
  type: surveyActions.RECEIVED_SUCCESS,
  payload: { survey },
})

const requestReload = () => ({
  type: surveyActions.REQUEST_RELOAD,
})

const fetchSurvey = (surveyId: string) => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken

    dispatch(requestSurvey())

    return getSurvey(idToken, surveyId).then(
      (response: any) => dispatch(receivedSuccess(response)),
      (error: any) => dispatch(receivedError(error.message)),
    )
  }
}

export { surveyActions, requestReload, fetchSurvey }
