import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { LayoutProps } from './props'
import { login } from '@/lib/firebase'

export default (): LayoutProps => {
  const idToken = useSelector<RootReducer, string | null>(({ idToken }) => idToken)

  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const onFinish = async (values: any) => {
    setLoading(true)
    const result = await login(values['email'], values['password'])

    if (result.type === 'Failure') {
      setErrorMessage('ログインできませんでした。メールアドレスとパスワードをご確認ください。')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (idToken) window.location.href = '/'
  }, [idToken])

  return {
    errorMessage,
    loading,
    onFinish,
  }
}
