import React from 'react'
import { WithoutAuth, WithAuth } from '@/components/Root'
import NotFound from '@/components/Error/NotFound'
import { LayoutProps } from './props'

export default ({ idToken }: LayoutProps) => {
  const title = 'ページが見つかりません'
  const Root = idToken ? WithAuth : WithoutAuth

  return (
    <Root title={title}>
      <NotFound />
    </Root>
  )
}
