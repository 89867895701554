import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { Layout, PageHeader, Form, Input, Button } from 'antd'
import { FormInstance } from 'antd/lib/form'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
}

type Props = {
  type: 'edit' | 'new'
  form: FormInstance
  onPressSubmit: (values: any) => void
  isUploadingSurvey: boolean
}

const generateTitle = (type: Props['type']) => {
  return type === 'edit' ? '実験編集' : '新規実験登録'
}
export default (props: Props) => {
  return (
    <Root title={generateTitle(props.type)}>
      <Layout>
        <StyledPageHeader title={generateTitle(props.type)} />
        <ContentBody>
          <Form {...layout} form={props.form} onFinish={props.onPressSubmit}>
            <Form.Item name="name" label="実験名" rules={[{ required: true, message: '実験名は必須です' }]}>
              <Input placeholder="実験名" />
            </Form.Item>
            <Form.Item label=" " colon={false}>
              <Button type="primary" htmlType="submit" loading={props.isUploadingSurvey}>
                {props.type === 'edit' ? '変更を保存' : '登録'}
              </Button>
            </Form.Item>
          </Form>
        </ContentBody>
      </Layout>
    </Root>
  )
}

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
