import { postSchedule, putSchedule, deleteSchedule } from '@/lib/request'
import { requestReload } from './survey'

const scheduleFormActions = {
  CHANGE_ID: 'SCHEDULE_CHANGE_ID',
  CHANGE_URL: 'SCHEDULE_CHANGE_URL',
  CHANGE_TRIGGER_DAY: 'SCHEDULE_CHANGE_TRIGGER_DAY',
  CHANGE_TRIGGER_HOUR: 'SCHEDULE_CHANGE_TRIGGER_HOUR',
  CHANGE_EXPIRE_DAY: 'SCHEDULE_CHANGE_EXPIRE_DAY',
  CHANGE_EXPIRE_HOUR: 'SCHEDULE_CHANGE_EXPIRE_HOUR',
  CHANGE_SCHEDULE: 'SCHEDULE_CHANGE_ALL',
  RESET_SCHEDULE: 'RESET_SCHEDULE',
  REQUEST_POST_SCHEDULE: 'SCHEDULE_REQUEST_POST',
  REQUEST_PUT_SCHEDULE: 'SCHEDULE_REQUEST_PUT',
  REQUEST_DELETE_SCHEDULE: 'SCHEDULE_REQUEST_DELETE',
  RECEIVED_ERROR: 'SCHEDULE_RECEIVED_ERROR',
  RECEIVED_SUCCESS: 'SCHEDULE_RECEIVED_SUCCESS',
}

const changeIdValue = (value: string) => ({
  type: scheduleFormActions.CHANGE_ID,
  payload: { value },
})

const changeUrlValue = (value: string) => ({
  type: scheduleFormActions.CHANGE_URL,
  payload: { value },
})

const changeTriggerDayValue = (value: number) => ({
  type: scheduleFormActions.CHANGE_TRIGGER_DAY,
  payload: { value },
})

const changeTriggerHourValue = (value: number) => ({
  type: scheduleFormActions.CHANGE_TRIGGER_HOUR,
  payload: { value },
})

const changeExpireDayValue = (value: number) => ({
  type: scheduleFormActions.CHANGE_EXPIRE_DAY,
  payload: { value },
})

const changeExpireHourValue = (value: number) => ({
  type: scheduleFormActions.CHANGE_EXPIRE_HOUR,
  payload: { value },
})

const changeSchedule = (value: Schedule) => ({
  type: scheduleFormActions.CHANGE_SCHEDULE,
  payload: { value },
})

const resetSchedule = () => ({
  type: scheduleFormActions.RESET_SCHEDULE,
})

const requestPostSchedule = () => ({
  type: scheduleFormActions.REQUEST_POST_SCHEDULE,
})

const requestPutSchedule = () => ({
  type: scheduleFormActions.REQUEST_PUT_SCHEDULE,
})

const requestDeleteSchedule = () => ({
  type: scheduleFormActions.REQUEST_DELETE_SCHEDULE,
})

const receivedError = (error: string) => ({
  type: scheduleFormActions.RECEIVED_ERROR,
  payload: { error },
})

const receivedSuccess = (message: string) => ({
  type: scheduleFormActions.RECEIVED_SUCCESS,
  payload: { message },
})

const submitPostSchedule = (surveyId: string) => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken
    const schedule = getState().scheduleForm.schedule

    dispatch(requestPostSchedule())

    return postSchedule(idToken, surveyId, schedule).then(
      (response: any) => {
        dispatch(receivedSuccess('Schedule Successfully added'))
        dispatch(requestReload())
      },
      (error: any) => dispatch(receivedError(error.message)),
    )
  }
}

const submitUpdateSchedule = (surveyId: string) => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken
    const schedule = getState().scheduleForm.schedule

    dispatch(requestPutSchedule())

    return putSchedule(idToken, surveyId, schedule.id, schedule).then(
      (response: any) => {
        dispatch(receivedSuccess('Schedule successfully updated'))
        dispatch(requestReload())
      },
      (error: any) => dispatch(receivedError(error.message)),
    )
  }
}

const submitDeleteSchedule = (surveyId: string) => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken
    const schedule = getState().scheduleForm.schedule

    dispatch(requestDeleteSchedule())

    return deleteSchedule(idToken, surveyId, schedule.id).then(
      (response: any) => {
        dispatch(receivedSuccess('Schedule successfully deleted'))
        dispatch(requestReload())
      },
      (error: any) => dispatch(receivedError(error.message)),
    )
  }
}

export {
  scheduleFormActions,
  submitPostSchedule,
  submitUpdateSchedule,
  submitDeleteSchedule,
  changeIdValue,
  changeUrlValue,
  changeTriggerDayValue,
  changeTriggerHourValue,
  changeExpireDayValue,
  changeExpireHourValue,
  changeSchedule,
  resetSchedule,
}
