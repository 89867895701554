import { useState, useEffect, useCallback } from 'react'
import { firestore, auth } from '@/lib/firebase'
import { SurveyScheduleRequest } from '@/lib/request'

export const useSurveySchedules = (params: { survey: App.Survey }) => {
  const [surveySchedules, setSurveySchedules] = useState<App.SurveySchedule[]>([])
  useEffect(() => {
    return firestore
      .collection(`surveys/${params.survey.id}/schedules`)
      .orderBy('trigger_day', 'asc')
      .orderBy('trigger_hour', 'asc')
      .onSnapshot(async a => {
        const p = await Promise.all(
          a.docs.map<Promise<App.SurveySchedule>>(async b => {
            const data = b.data()
            return {
              ...data,
              id: b.id,
            } as App.SurveySchedule
          }),
        )
        setSurveySchedules(p)
      })
  }, [])

  const deleteSurveySchedule = useCallback(async (id: App.SurveySchedule['id']) => {
    return new Promise(r => {
      auth.onAuthStateChanged(async a => {
        const token = await a?.getIdToken()
        await SurveyScheduleRequest.delete(token ?? '', { surveyId: params.survey.id, id })
        r()
      })
    })
  }, [])

  const cloneSurveySchedule = useCallback(async (surveySchedule: App.SurveySchedule) => {
    return new Promise(r => {
      auth.onAuthStateChanged(async a => {
        const token = await a?.getIdToken()
        await SurveyScheduleRequest.clone(token ?? '', { surveyId: params.survey.id, surveySchedule })
        r()
      })
    })
  }, [])

  return { surveySchedules, deleteSurveySchedule, cloneSurveySchedule }
}
