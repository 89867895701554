import { useRouteMatch } from 'react-router'
import { logout } from '@/lib/firebase'
import { Props, LayoutProps } from './props'

export default ({ title, children }: Props): LayoutProps => {
  document.title = `${title} | WorkerSense+`
  const { path: currentPath } = useRouteMatch()
  const selectedKey = currentSelectedKey(currentPath)

  return {
    selectedKey,
    logout,
    children,
  }
}

const currentSelectedKey = (path: string): string => {
  if (path.match(/^\/surveys/) !== null) {
    return 'surveys'
  } else {
    return ''
  }
}
